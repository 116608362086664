import React from 'react';
import { Checkbox } from 'react-input-checkbox';
/* --- import components ----*/
import { SentenceSmall } from '../Typography';
import { ErrorMessage } from '../Form';

const CheckboxComp = ({
  text,
  value,
  onChange,
  errorDisabled = true,
  errorIsVisible = false,
  errorMessage = '',
}) => {
  return (
    <>
      <div css={{ display: 'flex' }}>
        <Checkbox theme="material-checkbox" value={value} onChange={onChange}>
          <SentenceSmall
            css={{
              width: '100%',
              paddingLeft: 10,
            }}
          >
            {text}
          </SentenceSmall>
        </Checkbox>
      </div>
      {errorDisabled ? null : <ErrorMessage isVisible={errorIsVisible} message={errorMessage} />}
    </>
  );
};

export default CheckboxComp;
