import React from 'react';
import { EventDescription, Paragraph, TextContainer } from '../Typography';
import colors from 'src/style/colors';

/**
 * @type React.FunctionComponent<{ title?: string; subtitle?: string; text?: string; previousJob?: string, promo?: string, previousJobSection?: boolean }>
 */
const Description = ({ title, previousJob, site, text, previousJobSection }) => (
  <div>
    <EventDescription>{title}</EventDescription>
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div css={{ lineHeight: '12px' }}>
        {previousJobSection && (
          <Paragraph css={{ fontWeight: 'bold', paddingTop: 25, paddingBottom: 0 }}>
            {previousJob}
          </Paragraph>
        )}
      </div>
    </div>
    {text && <TextContainer>{text}</TextContainer>}
    {site && (
      <TextContainer
        css={{
          paddingTop: 0,
          '& a': {
            color: colors.purple,
            textDecoration: 'none',
          },
        }}
        dangerouslySetInnerHTML={{ __html: site }}
      />
    )}
  </div>
);

export default Description;
