import React from 'react';

const Check = ({ className = '', color = '#FCC01A', paddingRight = 20, marginTop = 4 }) => {
  return (
    <div css={{ paddingRight: paddingRight, marginTop: marginTop }}>
      <svg className={className} width="15" height="14" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M13.8133628.27203668c-1.1157535-.57931165-2.5211183-.20243604-3.1384173.83666385L6.39077522 8.30379434 3.93888673 6.01562099c-.90114113-.84097101-2.36188975-.84097101-3.26303088 0-.90114113.840971-.90114113 2.20418393 0 3.04515493l4.6153195 4.30714988C5.72732304 13.7760282 6.31577628 14 6.92269079 14c.10615235 0 .21345853-.0064607.31961087-.0215357.71652836-.0936806 1.34536564-.4953223 1.69728375-1.0864786l5.76914939-9.69108713c.6196066-1.04017669.2180738-2.35062703-.895372-2.9288619z"
          fill={color}
          fillRule="evenodd"
        />
      </svg>
    </div>
  );
};
export default Check;
