import React from 'react';
import { SubtitleDescription, Paragraph, TextContainer } from '../Typography';
import colors from 'src/style/colors';

/**
 * @type React.FunctionComponent<{ title: string; text?: string; subheading? : string, item?: array}>
 */

const DescriptionBlock = ({ title, children, item, subheading }) => (
  <div css={{ display: 'flex', flexDirection: 'column' }}>
    <SubtitleDescription
      css={{
        fontWeight: 'bold',
        '&:before': {
          content: '""',
          display: 'inline-block',
          height: 10,
          width: 10,
          marginRight: 10,
          marginTop: 20,
          backgroundColor: colors.yellow,
          borderRadius: '50%',
        },
      }}
    >
      {title}
    </SubtitleDescription>

    {children && <TextContainer>{children}</TextContainer>}
    {subheading && (
      <Paragraph css={{ fontWeight: 'bold', paddingBottom: 10, paddingTop: 0 }}>
        {subheading}
      </Paragraph>
    )}
    {item
      ? item.map(item => {
          return (
            <Paragraph css={{ padding: 0, lineHeight: '35px' }} key={item}>
              {item}
            </Paragraph>
          );
        })
      : ''}
  </div>
);

export default DescriptionBlock;
